import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, Space } from "antd";

const SelectCompanyModal = ({ data, open, onClose, onSubmit }) => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);

  useEffect(() => {
    console.log("DATA SELECT", data);
  }, [data]);

  const handleOk = () => {
    onSubmit({ ...data, company: selectedValue });
  };

  const handleCancel = () => {
    onClose(false);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <div>
      <Modal
        title="เลือกบริษัทที่ออกใบคำขอซื้อ"
        centered
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="พิมพ์ใบคำขอซื้อ"
        cancelText="ยกเลิก"
      >
        <Radio.Group onChange={handleChange} value={selectedValue}>
          <Space direction="vertical">
            <Radio value={1}>ONE LEASING</Radio>
            <Radio value={2}>KSM BILLIENAIR</Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default SelectCompanyModal;
