import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import carApi from "api/carApi";
import { currencyFormat } from "components/utils/Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const { Search } = Input;
export default function SearchContractModal({ open, onClose, onSubmit }) {
  const user = useSelector((state) => state.token);
  const [form] = Form.useForm();

  const [radioSelected, setRadioSelected] = useState(1);
  const [data, setData] = useState(null);

  const onSearch = (value) => {
    setData(null);
    form.resetFields();
    if (value) {
      fetchCarDetails(value);
    } else {
      setData(null);
      form.resetFields();
    }
  };

  const handleChangeRadio = (e) => {
    setRadioSelected(e.target.value);
    setData(null);
  };

  const fetchCarDetails = async (value) => {
    const result = await carApi.get("/salecar/ibm/loans", {
      params: { contractNo: value, company: radioSelected },
      headers: {
        "x-access-token": user.token,
      },
    });
    if (result.data) {
      setData(result.data);
    } else {
      alert("ไม่พบเลขสัญญานี้ ในฐานข้อมูล");
    }
    // console.log(result.data);
    // const [data] = result.data;
  };

  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [data]);

  const handleSubmit = () => {
    onSubmit([data]);
  };

  const handleClose = () => {
    onClose(false);
  };

  const CustomTextField = ({ text }) => {
    return (
      <div
        style={{
          border: "1px solid rgba(128,128,128,0.3)",
          borderRadius: "6px",
          padding: 4,
        }}
      >
        <Typography style={{ marginLeft: 4, color: "gray" }}>{text}</Typography>
      </div>
    );
  };

  return (
    <Modal
      title="ค้นหาจากเลขสัญญา"
      open={open}
      closeIcon={false}
      onCancel={handleClose}
      onOk={handleSubmit}
      footer={[
        <Button key="back" onClick={handleClose}>
          ยกเลิก
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={!data}
        >
          เพิ่มเข้าระบบ
        </Button>,
      ]}
    >
      <div>
        <Radio.Group style={{ width: "100%" }} defaultValue={1}>
          <Space direction="vertical" onChange={handleChangeRadio}>
            <Radio value={1}>บริษัท ONE Leasing</Radio>
            <Radio value={2}>บริษัท KSM</Radio>
          </Space>
        </Radio.Group>

        <Search
          style={{ width: "100%", marginTop: 16 }}
          placeholder="พิมพ์เลขที่สัญญา"
          allowClear
          enterButton="Search"
          // size="large"
          onSearch={onSearch}
        />
      </div>
      <Divider />
      {data && (
        <Form
          form={form}
          // onFinish={onFinish}
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          autoComplete="off"
          // onFinish={handleSubmit}
          // onFinishFailed={handleFinishFailed}
        >
          <Form.Item label="เลขที่สัญญา" name="CONTNO">
            <CustomTextField text={data?.CONTNO} />
          </Form.Item>
          <Form.Item label="เลขทะเบียน" name="REGNO">
            <CustomTextField text={data?.REGNO} />
          </Form.Item>

          <Form.Item label="จังหวัด" name="DORECV">
            <CustomTextField text={data?.DORECV} />
          </Form.Item>
          <Form.Item label="ยี่ห้อ" name="TYPE">
            <CustomTextField text={data?.TYPE} />
          </Form.Item>
          <Form.Item label="รุ่น" name="MODEL">
            <CustomTextField text={data?.MODEL} />
          </Form.Item>

          <Form.Item label="แบบ" name="BAAB">
            <CustomTextField text={data?.BAAB} />
          </Form.Item>

          <Form.Item label="สี" name="COLOR">
            <CustomTextField text={data?.COLOR} />
          </Form.Item>
          <Form.Item label="ปี" name="MANUYR">
            <CustomTextField text={data?.MANUYR} />
          </Form.Item>
          <Form.Item label="เลขเครื่อง" name="ENGNO">
            <CustomTextField text={data?.ENGNO} />
          </Form.Item>
          <Form.Item label="เลขคัตซี" name="STRNO">
            <CustomTextField text={data?.STRNO} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
