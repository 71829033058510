import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment/moment";
import "moment/locale/th";
import { useSelector } from "react-redux";

export default function EChart({ data }) {
  const user = useSelector((state) => state.token);
  const [result, setResult] = useState(data);

  useEffect(() => {
    setResult(data);
    console.log(data);
  }, [data]);

  const state = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: result.map((item) =>
          moment(item.sold_out_date).format("YYYY-MM")
        ),
      },
    },
    series: [
      {
        name: "ยอดขายประมูล",
        data: result.map((item) => item["total_auction_total"]),
      },
      {
        name: "ยอดขายปลีก",
        data: result.map((item) => item["total_retail_price"]),
      },
    ],
  };

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      width="100%"
    />
  );
}
