import React, { useState } from "react";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { Button, Modal } from "antd";
import moment from "moment";
import { currencyFormat } from "components/utils/Utils";
import carApi from "api/carApi";
import { useSelector } from "react-redux";

export default function AuctionByUserReportExcel({ data }) {
  const user = useSelector((state) => state.token);
  //   console.log("USER", user);
  //   const [data, setData] = useState([]);
  let dataTable = [];
  const fetchAuctionHighPrice = async () => {
    // setLoading(true);
    await carApi
      .get(`/salecar/admin/auction-log/active/users/${data.id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        dataTable = response.data;
      })
      .catch((err) => {
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "Sessions Expired",
          centered: true,
        });
      });
  };

  const downloadExcel = async () => {
    fetchAuctionHighPrice();
    Modal.confirm({
      title: "ดาวน์โหลด Report",
      content: `ดาวน์โหลดรายการประมูลของ ${data.firstname} ${data.lastname}`,
      onOk: () => {
        exportToExcel();
      },
    });
  };

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();

    const worksheet1 = workbook.addWorksheet("ยอดประมูลสูงสุด");

    worksheet1.columns = [
      { header: "วันที่", key: "time_stamp", width: 30 },
      { header: "ชื่อเต๊นท์รถ", key: "user_car_tent_name", width: 20 },
      { header: "เบอร์โทรเต๊นท์รถ", key: "user_car_tent_phone", width: 15 },
      { header: "ชื่อ", key: "user_name", width: 15 },
      { header: "สกุล", key: "user_lastname", width: 15 },
      { header: "โทรศัพท์", key: "user_phone", width: 15 },
      { header: "ยี่ห้อ", key: "brand", width: 15 },
      { header: "รุ่น", key: "model", width: 15 },
      { header: "ทะเบียน", key: "register_no", width: 20 },
      {
        header: "ราคาต้นทุน",
        key: "cost_price",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
      {
        header: "ยอดประมูล",
        key: "price",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
      {
        header: "กำไร/ขาดทุน",
        key: "summary",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
    ];

    worksheet1.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCFFCC" },
      };
      cell.font = { bold: true };
    });

    dataTable.forEach((item, index) => {
      const profitLoss = item.price - item.cost_price;
      const row = worksheet1.addRow({
        time_stamp: moment(item.time_stamp).format("lll"),
        user_car_tent_name: item.user_car_tent_name || "-",
        user_car_tent_phone: item.user_car_tent_phone || "-",
        user_name: item.user_name,
        user_lastname: item.user_lastname,
        user_phone: item.user_phone,
        brand: item.brand,
        model: item.model,
        register_no: item.register_no,
        cost_price: currencyFormat(item.cost_price),
        price: currencyFormat(item.price),
        summary: currencyFormat(profitLoss),
      });

      if (index % 2 === 0) {
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFE0B2" }, // สีส้มอ่อน
          };
        });
      }
      // Set font color for summary column
      const summaryCell = row.getCell("summary");
      summaryCell.font = {
        color: { argb: profitLoss < 0 ? "FFFF0000" : "FF006400" }, // สีแดงสำหรับค่าติดลบ, สีเขียวสำหรับค่าบวก
      };
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `รายการประมูล ${data.firstname} ${data.lastname}.xlsx`);
    });
  };

  return (
    <div
    //   style={{
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "flex-end",
    //     marginBottom: 24,
    //   }}
    >
      <Button type="primary" onClick={downloadExcel}>
        Excel
      </Button>
    </div>
  );
}
