import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductDetail from "./product_detail/ProductDetail";
import ProductList from "./buy_products/ProductList";
import AddProducts from "./add_products/AddProducts";
import TableProductList from "./table_product_list/TableProductList";
import AuctionCompletedList from "./auction/AuctionCompletedList";
import { useSelector } from "react-redux";

export default function Routers() {
  const user = useSelector((state) => state.token);
  // console.log("ROUTE", user);
  return (
    <Routes>
      <Route path="/:id" element={<ProductDetail />} />
      <Route path="/" element={<ProductList />} />
      {user?.role === "admin" ? (
        <>
          <Route path="/admin/add" element={<AddProducts />} />
          <Route path="/admin/list" element={<TableProductList />} />
          <Route
            path="/admin/actions-completed-list"
            element={<AuctionCompletedList />}
          />
        </>
      ) : null}
    </Routes>
  );
}
