import { Modal, Table, Typography } from "antd";
import { currencyFormat } from "components/utils/Utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";

export default function AuctionUserListModal({ open, data, onClose }) {
  const user = useSelector((state) => state.token);
  const [list, setList] = useState([]);

  useEffect(() => {
    console.log("USERLIST", data);
    setList(data);
  }, [data]);

  const handleClose = () => {
    onClose(false);
  };

  const columns = [
    {
      key: 1,
      width: 120,
      // dataIndex: "time_stamp",
      title: "เวลา",
      render: (rec) => {
        return moment(rec.time_stamp).add(-7, "hours").format("lll");
      },
    },
    {
      key: 3,
      // dataIndex: "ชื่อผู้มใ",
      title: "ผู้ทำการประมูล",
      render: (rec) => {
        return (
          <>
            <Link to={"/users/" + rec.user_id}>{rec.user_car_tent_name}</Link>
            <Typography>
              คุณ : {rec.user_name} {rec.user_lastname}
            </Typography>
            <Typography>โทร {rec.user_phone}</Typography>
          </>
        );
      },
    },
    user.id === 7 || user.id === 9 || user.id === 10
      ? {
          key: 3,
          width: 100,
          dataIndex: "cost_price",
          title: "ราคาต้นทุน",
          render: (rec) => {
            return (
              <Typography style={{ fontWeight: "bold" }}>
                {currencyFormat(rec)}
              </Typography>
            );
          },
        }
      : {},
    {
      key: 4,
      width: 100,
      dataIndex: "price",
      title: "ราคาประมูล",
      render: (rec) => {
        return (
          <Typography style={{ color: "blue", fontWeight: "bold" }}>
            {currencyFormat(rec)}
          </Typography>
        );
      },
    },
    user.id === 7 || user.id === 9 || user.id === 10
      ? {
          key: 5,
          width: 100,
          // dataIndex: "price",
          title: "กำไร/ขาดทุน",
          render: (rec) => {
            const cost_price = rec.cost_price || 0;
            const price = rec.price || 0;
            const sum = price - cost_price;
            // let color = "green";
            console.log("SUM", sum);
            if (sum < 0) {
              return (
                <Typography style={{ color: "red", fontWeight: "bold" }}>
                  {currencyFormat(sum)}
                </Typography>
              );
            }
            return (
              <Typography style={{ color: "green", fontWeight: "bold" }}>
                +{currencyFormat(sum)}
              </Typography>
            );
          },
        }
      : {},
  ];

  //   const renderList = () => {
  //     return (
  //       <Space>
  //         <Typography>เวลา</Typography>
  //         <Typography>ผู้ประมูล</Typography>
  //         <Typography>ยอดประมูล</Typography>
  //       </Space>
  //     );
  //   };

  return (
    <Modal
      width={800}
      title="รายชื่อผู้ร่วมประมูล"
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <Table dataSource={list} columns={columns} />
    </Modal>
  );
}
