import React, { useState, useEffect } from "react";
import { Button, Divider, Space, Typography } from "antd";
import SearchContractModal from "./SearchContractModal";
import carApi from "api/carApi";
import UploadContractList from "./UploadContractList";
import TableCarList from "./TableCarList";
import { useSelector } from "react-redux";
import Loading from "components/Loading";

export default function Main() {
  const user = useSelector((state) => state.token);
  // console.log(user);
  const [openSearchModal, setOpenSearchModal] = useState();
  const [openUploadModal, setOpenUploadModal] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickSearch = () => {
    setOpenSearchModal(true);
  };

  useEffect(() => {
    fetchDataFromStock();
  }, []);

  // useEffect(() => {
  //   const uniqueData = Object.values(
  //     dataSource.reduce((acc, item) => {
  //       acc[item.CONTNO] = item; // ใช้ CONTNO เป็น key ใน object
  //       return acc;
  //     }, {})
  //   );

  //   setDataSource(uniqueData);
  // }, [dataSource]);

  const fetchDataFromStock = async () => {
    const result = await carApi.get("/salecar/stocks", {
      headers: { "x-access-token": user.token },
    });
    const [data] = result.data;
    // console.log(data);
    setDataSource(data);
  };

  const handleSubmitContract = async (data) => {
    setOpenSearchModal(false);
    setOpenUploadModal(false);
    setLoading(true);

    try {
      // สร้าง array ของ promises จากการ insert ข้อมูล
      const promises = data.map((item) => {
        return insertCarStocks(item); // return Promise
      });

      // รอให้ทุก Promise เสร็จสิ้น
      await Promise.all(promises);
      console.log("ข้อมูลถูกส่งสำเร็จ");
      setLoading(false);
      // อัพเดต dataSource หลังจากที่ส่งข้อมูลเสร็จสิ้นทั้งหมด
      fetchDataFromStock();
      // setDataSource((pre) => [...pre, ...data]);
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการส่งข้อมูล:", error);
      setLoading(false);
    }
  };

  const insertCarStocks = async (data) => {
    try {
      await carApi.post("/salecar/stocks", data, {
        headers: {
          "x-access-token": user.token,
        },
      });
      console.log("ข้อมูลถูกส่งสำเร็จ:", data);
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการส่งข้อมูล:", error);
      throw error; // เพื่อให้ Promise ใน Promise.all.reject
    }
  };

  const handleClickUpload = () => {
    setOpenUploadModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <h3>สต๊อกรถยึดขาด</h3>
        <Divider />
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <Button type="primary" onClick={handleClickSearch}>
            ค้นเลขสัญญาจากฐานข้อมูล
          </Button>
          <Button onClick={handleClickUpload}>อัพโหลดไฟล์ Excel</Button>
        </div>
      </div>
      <Divider />

      <TableCarList data={dataSource} editMenu={true} />
      <SearchContractModal
        open={openSearchModal}
        onClose={setOpenSearchModal}
        onSubmit={handleSubmitContract}
      />

      <UploadContractList
        open={openUploadModal}
        onClose={setOpenUploadModal}
        onSubmit={handleSubmitContract}
      />
    </div>
  );
}
