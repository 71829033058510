import React, { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import Content from "./Content";
import SignUp from "components/login/SignUp";
import LogIn from "components/login/LogIn";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";

export default function Layout() {
  const user = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    // console.log("USER", user);
    try {
      if (pathname === "/") {
        if (user?.role === "seller") {
          navigate("/retails");
        } else {
          // console.log("Navigate", user);
          navigate("/products");
        }
      }
      // if (!user) {
      //   dispatch(addToken(null));
      //   navigate("/login");
      // }
    } catch (err) {
      dispatch(addToken(null));
      // navigate("/login");
    }
  }, [user, pathname]);

  if (pathname === "/sign-up") {
    return <SignUp />;
  } else if (pathname === "/login") {
    // dispatch(addToken(null));
    // navigate("/login");
    return <LogIn />;
  }
  if (pathname && user?.role) {
    return (
      <>
        <CssBaseline></CssBaseline>
        <Header></Header>
        <Content />
      </>
    );
  } else {
    dispatch(addToken(null));
    navigate("/login");
  }
}
