import { Button, Modal, Table, Typography } from "antd";
import carApi from "api/carApi";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuctionByUserReportExcel from "./AuctionByUserReportExcel";

const { Text } = Typography;

export default function SelectUserListReportModal({ open, onClose }) {
  const user = useSelector((state) => state.token);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    const result = await carApi.get("/salecar/admin/users/auctioneer", {
      headers: {
        "x-access-token": user.token,
      },
    });
    console.log("USERLIST", result.data);
    setDataSource(result.data);
  };

  const handleClose = () => {
    onClose(false);
  };
  const columns = [
    {
      title: "ชื่อเต็นท์รถ",
      dataIndex: "car_tent_name",
      key: "car_tent_name",
    },
    {
      title: "ชื่อ",
      //   dataIndex: "firstname",
      key: "name",
      render: (rec) => {
        return (
          <div>
            <Text>
              {rec.firstname} {rec.lastname}{" "}
            </Text>
            {rec.nickname ? <Text>({rec.nickname})</Text> : null}
          </div>
        );
      },
    },

    {
      title: "จำนวนการประมูล (ครั้ง)",
      dataIndex: "auction_count",
      key: "auction_count",
    },

    {
      title: "ดาวน์โหลด",
      //   dataIndex: "firstname",
      key: "name",
      render: (rec) => {
        return (
          <div>
            <AuctionByUserReportExcel data={rec} />
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Modal
        title="รายชื่อผู้ประมูล"
        width={1000}
        destroyOnClose
        open={open}
        onOk={handleClose}
        // closeIcon={false}
        onCancel={handleClose}
        // footer={
        //   [
        //     <Button key="delete" danger onClick={handleDelete}>
        //       ลบสินค้านี้
        //     </Button>,
        //     <Button
        //       key="close"
        //       // href="https://google.com"
        //       // loading={loading}
        //       onClick={handleClose}
        //     >
        //       ยกเลิก
        //     </Button>,
        //     <Button key="submit" type="primary" onClick={handleSubmit}>
        //       บันทึก
        //     </Button>,
        //   ]
        // }
      >
        <Table dataSource={dataSource} columns={columns} size="small" />
      </Modal>
    </div>
  );
}
